<template>
    <div class="project_list">
        <div class="edit_btn">
            <el-dropdown>
                <span class="el-dropdown-link">
                    <i class="el-icon-more" style="color: #000"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        icon="el-icon-menu"
                        @click.native="openWork(info.id)"
                        >进入工程</el-dropdown-item
                    >
                    <el-dropdown-item
                        icon="el-icon-delete-solid"
                        @click.native="delProje(info.id)"
                        >删除工程</el-dropdown-item
                    >
                    <el-dropdown-item
                        icon="el-icon-edit"
                        @click.native="reset_name(info.id)"
                        >重命名</el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-image style="width: 100%" :src="info.coverUrl"></el-image>
        <div class="info">
            <div class="card-title">
                {{ info.title }}
            </div>
            <div>{{ info.timer }}</div>
        </div>
    </div>
</template>

<script>
import { getProje, delProje, updata_project } from "@/api/engi.js";
export default {
    name: "ProjectList",
    props: ["poject_data"],
    data() {
        return {
            info: this.poject_data,
        };
    },
    mounted() {},

    methods: {
        delProje(uid, index) {
            this.$confirm("此操作将永久删除此工程, 是否继续操作?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: "创建中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.6)",
                    });
                    delProje(uid).then((res) => {
                        if (res.code == 200) {
                            this.$emit("removeItem", uid);
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message.error("删除失败!");
                        }
                    });
                    loading.close();
                })
                .catch(() => {
                    // this.$message.error( "删除失败!");
                });
        },
        openWork(projId) {
            this.$router.push(`/make/editing?projId=${projId}`);
        },
        reset_name(pid) {
            this.$prompt("请输入工程名字", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                // inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
                // inputErrorMessage: "文件命不能包含特殊字符跟空格",
            }).then(({ value }) => {
                const req = {
                    id: pid,
                    title: value,
                };
                updata_project(req).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("修改成功");
                        this.info.title = value;
                        console.log(this.info);
                    }
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.project_list {
    position: relative;
    line-height: 0;
    border-radius: 8px;
    overflow: hidden;
    .info {
        padding: 4px 8px;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 999;
        font-size: 12px;
        line-height: 1.3;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        box-sizing: border-box;
    }
    .edit_btn {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 99;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 0 2px;
        border-radius: 4px;
    }
}
</style>