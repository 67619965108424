<template>
    <div class="project_content">
        <!--  <waterfall :col="7" :data="list">
           <template>
                <div
                    class="cell-item"
                    v-for="(item, index) in list"
                    :key="index"
                >
                    <img v-if="item.img" :src="item.img" alt="加载错误" />
                    <div class="item-body">
                        <div class="item-desc">{{ item.title }}</div>
                        <div class="item-footer">
                        </div>
                    </div>
                </div>
            </template>
        </waterfall> -->
        <!-- 
            imgsArr 数据
            srcKey 图片所在参数
            hrefKey  链接所在参数
         -->
        <!-- <vueWaterfallEasy
            :imgsArr="list"
            srcKey="coverUrl"
            :maxCols="10"
            :gap="10"
        >
        </vueWaterfallEasy> -->
        <!-- <Waterfall :line-gap="200" :watch="list">
            <WaterfallSlot
                v-for="(item, index) in list"
                :height="item.height"
                :width="item.width"
                :order="index"
                line-gap="4"
                :key="item.id"
            >
                <PROJECT_LIST :poject_data="item" />
            </WaterfallSlot>
        </Waterfall> -->
        <div class="project_box">
            <div
                class="project_item"
                v-for="(item, index) in list"
                :key="index"
            >
                <PROJECT_LIST :poject_data="item" :key="item.id" />
            </div>
        </div>
        <div class="project_page">
            <el-pagination
                background
                layout="total,prev, pager, next"
                :total="total"
                :page-size="getProjePage.size"
                @current-change="changePage"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getProje, delProje } from "@/api/engi.js";
import PROJECT_LIST from "@/components/work/project_list";
import moment from "moment";
// import Waterfall from "vue-waterfall/lib/waterfall";
// import WaterfallSlot from "vue-waterfall/lib/waterfall-slot";
// import vueWaterfallEasy from "vue-waterfall-easy";
import waterfall from "vue-waterfall2";
export default {
    name: "project_content",
    components: {
        PROJECT_LIST,
        // vueWaterfallEasy,
        // waterfall,
    },
    data() {
        return {
            list: [],
            getProjePage: {
                size: 12,
                current: 1,
                projectType: "BlendClip",
            },
            total: 0,
        };
    },

    mounted() {
        this.getProjeList();
    },

    methods: {
        async getProjeList() {
            let list = await getProje(this.getProjePage);
            console.log(list);
            this.total = list.data.total;
            // console.log(list.data.total);
            this.list = list.data.records;
            this.list.map((item) => {
                item.scriptClipsParam = JSON.parse(item.scriptClipsParam);
                // console.log(item.scriptClipsParam);
                item.src = "aaaa";
                item.rate = JSON.parse(item.scriptClipsParam.rate);
                item.timer = moment(item.createTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                );
            });
        },
        delProje(uid, index) {
            this.$confirm("此操作将永久删除此工程, 是否继续操作?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: "创建中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.6)",
                    });
                    delProje(uid).then((res) => {
                        if (res.code == 200) {
                            this.list.splice(index, 1);
                            this.getProjeList();
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message.error("删除失败!");
                        }
                    });
                    loading.close();
                })
                .catch(() => {
                    // this.$message.error( "删除失败!");
                });
        },
        openWork(projId) {
            this.$router.push(`/make/editing?projId=${projId}`);
        },
        changePage(val) {
            this.getProjePage.current = val;
            this.getProjeList();
        },
    },
};
</script>

<style lang="less" scoped>
.project_content {
    display: flex;
    flex-direction: column;
}
.project_box {
    flex: 1;
    height: 0;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    .project_item {
        // flex: 1;
        max-width: 240px;
        padding: 6px;
    }
}
.project_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .project_box {
        // flex: 1;
        // background-color: #fff;
        // height: 0;
        // overflow: auto;
        // padding: 10px;
        // display: flex;
    }
    .project_page {
        margin-top: 10px;
        background-color: #fff;
        padding: 10px 0;
    }
}
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;

    .tit_content {
        display: flex;
        align-items: center;

        .tit_txt {
            font-size: 15px;
            font-weight: bold;
            margin-right: 10px;
        }

        .tit_tabs span {
            padding: 0 4px;
            font-size: 13px;
        }

        .tit_tabs span.active {
            color: #349ffd;
            font-weight: bold;
        }
    }

    .move_btn {
        font-size: 12px;
    }
}

.add-project:hover {
    color: #349ffd;
}
.card-title {
    color: #334681;
}
.card-info {
    color: #8590b3;
}
.add-project {
    height: 330px;
    cursor: pointer;
    .add-project-btn {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        i {
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
}
.input-row + .input-row {
    margin-top: 10px;
}
.proje-info {
    font-size: 12px;
    height: 200px;
    background-color: #f3f3f3;
    position: relative;
}
.edit_btn {
    position: absolute;
    right: 12px;
    top: 10px;
    z-index: 999;
    padding: 0 4px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
}
</style>