<template>
    <div class="project_content" v-infinite-scroll="load">
        <!-- <project_content /> -->
        <PROJECTS :autoLoad="true" />
    </div>
</template>

<script>
import { getProje, delProje } from "@/api/engi.js";
import project_content from "@/components/project_list";
import PROJECTS from "@/components/v3/project_list";
import moment from "moment";
export default {
    name: "project_list",
    components: { PROJECTS },
    data() {
        return {
            current: 1,
            // list: [],
            // pages: 1,
            // where: {
            //     Action: "SearchEditingProject",
            //     PageNo: 0,
            //     PageSize: 10,
            // },
        };
    },

    mounted() {},

    methods: {
        load() {
            console.log(this.current);
        },
        // openWork(projId) {
        //     this.$router.push(`/make/editing?projId=${projId}`);
        // },
        // getProjeList() {
        //     getProje(this.where).then((res) => {
        //         this.pages = res.data.pages;
        //         this.list.push(res.data.records);
        //     });
        // },
        // async getData() {
        //     let current = this.where.PageNo + 1;
        //     if (current <= this.pages) {
        //         this.where.PageNo += 1;
        //         let res = await getProje(this.where);
        //         this.pages = res.data.pages;
        //         this.list = [...this.list, ...res.data.records];
        //     } else {
        //         console.log("nopages" + current);
        //     }
        // },
    },
};
</script>

<style lang="less" scoped>
.project_content {
    height: 100%;
    // overflow: hidden;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
}
</style>